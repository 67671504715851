<template>
  <div :class="['code-snippet', background, 'px-4 py-2']" @mouseenter="showCopy = true" @mouseleave="showCopy = false">
    {{ code }}
    <v-btn small icon class="copy-button ma-2" v-show="showCopy" @click="copy">
      <v-icon small>mdi-content-copy</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "CodeSnippet",
  props: {
    code: String,
    enableCopy: Boolean,
    dark: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    background() {
      return this.$vuetify.theme.dark || this.dark ? 'grey darken-3' : 'grey lighten-3'
    }
  },
  data() {
    return {
      showCopy: false,
    }
  },
  methods: {
    async copy() {
      await navigator.clipboard.writeText(this.code);
      this.$nSuccess("Copié !")
    }
  }
}
</script>

<style scoped>
  .code-snippet {
    position: relative;
    font-family: monospace;
  }

  .copy-button {
    position: absolute;
    top: 0;
    right: 0;
  }
</style>