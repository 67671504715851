<template>
    <v-card>
      <div class="d-flex align-center justify-center">
        <h4> {{ title }}</h4>
        <v-btn icon @click="deleteContact">
        <v-icon
        color="red"
        title="Supprimer le contact"
        >mdi-account-remove</v-icon>
        </v-btn>
      </div>
      <div class="d-flex flex-row align-start justify-space-between">
        <v-card-text style="width: 48%">
          <v-text-field label="Nom" v-model="contact.firstname"></v-text-field>
          <v-text-field label="Mail" v-model="contact.email"></v-text-field>
        </v-card-text>
        <v-card-text style="width: 48%">
          <v-text-field label="Prénom" v-model="contact.lastname"></v-text-field>
          <v-text-field label="Téléphone" v-model="contact.phone"></v-text-field>
        </v-card-text>
      </div>
    </v-card>
  </template>
  
  <script>
  export default {
    props: {
      title: String,
      contact: Object,
    },
    methods: {
      deleteContact() {
        // Émettre un événement pour signaler la suppression du contact
        this.$emit('delete-contact');
      },
    },
  };
  </script>
  