<template>
  <v-card :key="modalKey">
    <v-toolbar color="primary" dark>
      <v-toolbar-title>
        Nouveau client
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon @click="closeModal">mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-form @submit.prevent="onSubmit">
      <div class="d-flex mx-2 flex-row align-start justify-space-between">
        <div
          class="d-flex flex-column align-stretch justify-space-between"
          style="width: 48%"
        > 
          <div class="d-flex justify-space-between">
            <v-col cols="6">
              <v-text-field 
                label="Nom*"
                v-model="clientData.name"
                :rules="[clientNameRule]"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field 
                label="Nom commercial"
                v-model="clientData.commercialName"
              ></v-text-field>
            </v-col>
          </div> 
          <!-- Contact commercial -->
          <div class="d-flex align-center">
            <v-toolbar class="elevation-0">
              <h3>Contact commercial</h3>
              <v-badge
                class=""
                color="primary"
                :content="clientData.contactsCommercial && clientData.contactsCommercial.length || '0'"
                inline
              ></v-badge>
              <v-btn icon @click="addContact('contactsCommercial')">
                <v-icon title="Ajouter un contact" color="primary" >mdi-account-multiple-plus</v-icon>
              </v-btn>
              <v-btn v-if="clientData.contactsCommercial && clientData.contactsCommercial.length > 0" icon @click="toggleContacts('showCommercialContacts')">
                <v-icon>{{ showCommercialContacts ? 'mdi-chevron-up': 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-toolbar>
          </div>
          <div class="contact-container" v-if="showCommercialContacts">
            <contact-field v-for="(contact, index) in clientData.contactsCommercial" :key="contact.id" :title="`${contact.firstname + ' ' + contact.lastname + ' '} ${index + 1}`" :contact="contact" @delete-contact="deleteContact('contactsCommercial', index)"/>
          </div>
          
          <!-- Contact projet -->
          <div class="d-flex align-center">
            <v-toolbar class="elevation-0">
              <h3>Contact projet</h3>
              <v-badge
                class=""
                color="primary"
                :content="clientData.contactsProjet && clientData.contactsProjet.length || '0'"
                inline
              ></v-badge>
              <v-btn icon @click="addContact('contactsProjet')">
                <v-icon title="Ajouter un contact" color="primary" >mdi-account-multiple-plus</v-icon>
              </v-btn>
              <v-btn v-if="clientData.contactsProjet.length > 0" icon @click="toggleContacts('showProjetContacts')">
                <v-icon>{{ showProjetContacts ? 'mdi-chevron-up': 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-toolbar>
          </div>
          <div class="contact-container" v-if="showProjetContacts">
            <contact-field v-for="(contact, index) in clientData.contactsProjet" :key="contact.id" :title="`${contact.firstname + ' ' + contact.lastname + ' '} ${index + 1}`" :contact="contact" @delete-contact="deleteContact('contactsProjet', index)"/>
          </div>
          
          <!-- Contact PCCRISE -->
          <div class="d-flex align-center">
            <v-toolbar class="elevation-0">
              <h3>Contact PCCRISE</h3>
              <v-badge
                class=""
                color="primary"
                :content="clientData.contactsPCCrise && clientData.contactsPCCrise.length || '0'"
                inline
              ></v-badge>
              <v-btn icon @click="addContact('contactsPCCrise')">
                <v-icon title="Ajouter un contact" color="primary" >mdi-account-multiple-plus</v-icon>
              </v-btn>
              <v-btn v-if="clientData.contactsPCCrise.length > 0"
              icon @click="toggleContacts('showPCCriseContacts')">
                <v-icon>{{ showPCCriseContacts ? 'mdi-chevron-up': 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-toolbar>
              
          </div>
          <div class="contact-container" v-if="showPCCriseContacts">
            <contact-field v-for="(contact, index) in clientData.contactsPCCrise" :key="contact.id" :title="`${contact.firstname + ' ' + contact.lastname + ' '}Contact  ${index + 1}`" :contact="contact" @delete-contact="deleteContact('contactsPCCrise', index)"/>
          </div>
          
          <!-- Contact comptabilité -->
            <div class="d-flex align-center ">
              <v-toolbar class="elevation-0">
                <h3>Contact comptabilité</h3>
                <v-badge
                  class=""
                  color="primary"
                  :content="clientData.contactComptabilite && clientData.contactComptabilite.length || '0'"
                  inline
                ></v-badge>
                <v-btn icon @click="addContact('contactComptabilite')">
                  <v-icon title="Ajouter un contact" color="primary" >mdi-account-multiple-plus</v-icon>
                </v-btn>
                <v-btn v-if="clientData.contactComptabilite.length > 0" icon @click="toggleContacts('showComptabiliteContacts')">
                  <v-icon>{{ showComptabiliteContacts ? 'mdi-chevron-up': 'mdi-chevron-down' }}</v-icon>
                </v-btn>
              </v-toolbar>
            </div>
          <div class="contact-container" v-if="showComptabiliteContacts">
            <contact-field  v-for="(contact, index) in clientData.contactComptabilite" :key="contact.id" :title="`${contact.firstname + ' ' + contact.lastname + ' '}Contact  ${index + 1}`" :contact="contact" :index="index" @delete-contact="deleteContact('contactComptabilite', index)"/>
          </div>
        </div>
        <v-divider vertical></v-divider>
        <!-- right form -->
        <div
          class="d-flex flex-column align-stretch justify-space-between"
          style="width: 48%"
        >
          
          <!-- Informations contrat -->
          <div class="ml-3 mt-8 d-flex align-center">
            <h3>Informations contrat</h3>
            <!-- icon info -->
            <v-icon color="primary" class="ml-2">mdi-information</v-icon>
          </div>
            <v-divider></v-divider>
            <div class="d-flex flex-row align-start justify-space-between">
              <v-card-text style="width: 48%">
                <v-text-field
                  label="TVA"
                  v-model="clientData.informationContrat.tva"
                ></v-text-field>
                <v-text-field
                  label="N° Contrat"
                  v-model="clientData.informationContrat.contractNumber"
                ></v-text-field>
                <v-menu>
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      label="Date de résiliation"
                      :value="clientData.informationContrat.terminationDate | formatDate"
                      v-on="on"
                    >
                      {{
                        clientData.informationContrat.terminationDate
                          | formatDate
                      }}
                    </v-text-field>
                  </template>
                  <v-date-picker
                    style="width: 100%"
                    v-model="clientData.informationContrat.terminationDate"
                    color="primary"
                    :locale="$i18n.locale" 
                  ></v-date-picker>
                </v-menu>
              </v-card-text>
              <v-card-text style="width: 48%">
                <v-text-field
                  label="Adresse de facturation"
                  v-model="clientData.informationContrat.billingAddress"
                ></v-text-field>
                <v-text-field
                  label="SIRET"
                  v-model="clientData.informationContrat.siret"
                ></v-text-field>
                
                <v-menu>
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      label="Date signature"
                      :value="clientData.informationContrat.signatureDate | formatDate"
                      v-on="on"
                    >
                      {{
                        clientData.informationContrat.signatureDate | formatDate
                      }}
                    </v-text-field>
                  </template>
                  <v-date-picker
                    style="width: 100%"
                    v-model="clientData.informationContrat.signatureDate"
                    color="primary"
                    :locale="$i18n.locale"
                  ></v-date-picker>
                </v-menu>
              </v-card-text>
            </div>
          <!-- checkbox -->
          <div class="d-flex flex-column justify-center">
            <v-expansion-panels style="width: 50%">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <span>Sélectionner les services</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-checkbox
                    v-for="service in avaliableServices"
                    v-model="clientData.informationContrat.offeredServices"
                    :key="service"
                    :label="service"
                    :value="service"
                    color="primary"
                    hide-details
                  ></v-checkbox>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </div>

      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue-darken-1" variant="text" @click="closeModal">
          Annuler
        </v-btn>
        <v-btn color="success" variant="text" @click="saveAndCloseModal" :disabled="isSubmitDisabled">
          Créer
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import ContactField from './contactField';
import ClientService from '@/Services/SupportVision/ClientService';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'basesCreate',
  components: {
    ContactField
  },
  data: () => ({
    index: 0,
    dialogCreate: false,
    showCommercialContacts: true,
    showProjetContacts: true,
    showPCCriseContacts: true,
    showComptabiliteContacts: true,
    clientData: {
      name: '',
      commercialName: '',
      contactsCommercial: [],
      contactsProjet: [],
      contactsPCCrise: [],
      contactComptabilite: [],
      informationContrat: {
        contractNumber: '',
        tva: '',
        billingAddress: '',
        siret: '',
        offeredServices: [],
        signatureDate: '',
        terminationDate: '',
      },
      selectedServices: [],
      services: false,
      planetOnlineFull: false,
      trasheures: false,
      planeteBooking: false,
    },
    avaliableServices: ['planetOnlineFull', 'trasheures', 'planeteBooking'],
    modalKey: 0,
    clientNameRule: (v) => !!v || 'Ce champ est requis', 
  }),
  // formater les dates année-mois-jour en anglais
  filters: {
    formatDate(date) {
      if (!date) return '';
      return moment(date).format('DD/MM/YYYY');
    },
  },
  computed: {
    isSubmitDisabled() {
      return !this.clientData.name;
    },
  },
  methods: {
    closeModal() {
      this.dialogCreate = false;
      this.$emit('close-modal');
      this.resetForm();
    },
    resetForm() {
      this.clientData = {
        name: '',
        commercialName: '',
        contactsCommercial: [],
        contactsProjet: [],
        contactsPCCrise: [],
        contactComptabilite: [],
        informationContrat: {
          contractNumber: '',
          offeredServices: [],
          signatureDate: '',
          terminationDate: '',
          tva: '',
          billingAddress: '',
          siret: ''
        },
        selectedServices: [],
        services: false,
        planetOnlineFull: false,
        trasheures: false,
        planeteBooking: false,
      };
      this.clientNameRule = (v) => !!v || 'Ce champ est requis'; 
      this.modalKey += 1;
    },
    generateUniqueId() {
      return uuidv4();
    },
    toggleContacts(contactType) {
      this[contactType] = !this[contactType];
    },
    addContact(contactType) { 
      const contacts = this.clientData[contactType];
      if (!contacts) {
        return;
      }
      if (contactType === 'contactComptabilite') {
        contacts.push({
          id: this.generateUniqueId(),
          lastname: '',
          firstname: '',
          email: '',
          phone: '',
        });
        return;
      }
      // Ajoutez un nouvel objet de contact avec un ID unique
      contacts.push({
        id: this.generateUniqueId(), 
        lastname: '',
        firstname: '',
        email: '',
        phone: ''
      });
    },
    deleteContact(contactType, index) {
      this.clientData[contactType].splice(index, 1);
    },
    async saveAndCloseModal() {
      const newClient = { ...this.clientData }; 
      const res = await ClientService.createClient(newClient);
      if (res && res.message == 'Client déjà existant') {
        this.$nError('Client déjà existant');
      } else {
        this.$nSuccess('Client créé avec succès');
        this.$emit('client-created');
        this.closeModal();
      }
    },
  },
};
</script>

<style scoped>
.contact-container {
  max-height: 30vh; /* Définissez la taille maximale ici */
  overflow-y: auto; /* Permettre le défilement vertical si nécessaire */
  /* afficher la barre de defilement */
  scrollbar-width: thin;
  scrollbar-color: #c5c5c5 #f5f5f5;
  height: 100%;
}
</style>
