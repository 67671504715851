<template>  
    <v-card>
      <v-toolbar color="danger" dense>
        <v-toolbar-title>Confirmation de suppression</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pa-4">
        <span>Êtes-vous sûr(e) de vouloir supprimer cette configuration ?</span>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeModal" >Annuler</v-btn>
        <v-btn color="error" @click="deleteClient" >Confirmer</v-btn>
      </v-card-actions>
    </v-card>
</template>
<script>

import ClientService from '@/Services/SupportVision/ClientService';

export default {
  name: 'alertDeleteClient',
  data: () => ({
    alertDelete: false,
  }),
  computed: {
    clientId() {
      return this.$store.state.selectedClientId;
    },
  },  
  methods: {
    closeModal() {
      this.alertDelete = false;
      this.$emit('close-modal');
    },
    async deleteClient() {
      await ClientService.removeClient(this.clientId);
      this.closeModal();
    },
  }
}

</script>
