<template>
    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>
          Editer compte client
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon @click="closeModal">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text style="max-height: 70vh">
        <div>
          <span class="subtitle-2">ID CLIENT</span>
          <br />
          <code-snippet title="Copier" :code="client._id" enable-copy> </code-snippet>
        </div>
      </v-card-text>

      <div class="d-flex flex-row align-start justify-space-between"
      >
        <vue-scroll class="mr-4"
          style="width: 50%; max-height: 70vh;">
          <div
            class="d-flex flex-column align-stretch justify-space-between"
            style="width: 95%; max-height: 70vh;"
          >
            <!-- noms -->
            <div class="d-flex justify-space-between">
              <v-col cols="6" class="pa-0">
                <v-card-text>
                  <v-text-field label="Nom*" v-model="client.name" :rules="[v => !!v || 'Ce champ est obligatoire']"></v-text-field>
                </v-card-text>
              </v-col>
              <v-col cols="6" class="pa-0">
                <v-card-text>
                  <v-text-field
                    label="Nom commercial"
                    v-model="client.commercialName"
                  ></v-text-field>
                </v-card-text>
              </v-col>
            </div>

            <!-- Contact commercial -->
            <div class="d-flex align-center">
              <v-toolbar class="elevation-0">
                <h3>Contact commercial</h3>
                <v-badge
                  class=""
                  color="primary"
                  :content="client.contactCommercial && client.contactCommercial.length || '0'"
                  inline
                ></v-badge>
                <v-btn 
                  icon 
                  @click="addContact('contactCommercial')"
                  >
                  <v-icon title="Ajouter un contact" color="primary" >mdi-account-multiple-plus</v-icon>
                </v-btn>
                <v-btn v-if="client.contactCommercial && client.contactCommercial.length > 0"
                  icon 
                  @click="toggleContacts('contactCommercial')"
                >
                  <v-icon>{{ contactCommercial ? 'mdi-chevron-up': 'mdi-chevron-down' }}</v-icon>
                </v-btn>
              </v-toolbar>
            </div>
            <div class="contact-container" v-if="contactCommercial">
              <contact-field
                v-for="(contact, index) in client.contactCommercial"
                :key="index"
                :contact="contact"
                :title="`${contact.firstname + ' ' + contact.lastname + ' '}Contact  ${index + 1}`"
                @delete-contact="deleteContact('contactCommercial', index)"
              ></contact-field>
            </div>

            <!-- Contact projet -->
            <div class="d-flex align-center">
              <v-toolbar class="elevation-0">
                <h3>Contact projet</h3>
                <v-badge
                  class=""
                  color="primary"
                  :content="client.contactProjet && client.contactProjet.length || '0'"
                  inline
                ></v-badge>
                <v-btn icon @click="addContact('contactProjet')">
                  <v-icon title="Ajouter un contact" color="primary" >mdi-account-multiple-plus</v-icon>
                </v-btn>
                <v-btn v-if="client.contactProjet && client.contactProjet.length > 0" 
                  icon 
                  @click="toggleContacts('contactProjet')"
                  >
                  <v-icon>{{ contactProjet ? 'mdi-chevron-up': 'mdi-chevron-down' }}</v-icon>
                </v-btn>
              </v-toolbar>
            </div>
            <div class="contact-container" v-if="contactProjet">
              <contact-field
                v-for="(contact, index) in client.contactProjet"
                :key="index"
                :contact="contact"
                :title="`${contact.firstname + ' ' + contact.lastname + ' '}Contact  ${index + 1}`"
                @delete-contact="deleteContact('contactProjet', index)"
              ></contact-field>
            </div>

            <!-- Contact PC Crise -->
            <div class="d-flex align-center">
              <v-toolbar class="elevation-0">
                <h3>Contact PC Crise</h3>
                <v-badge
                  class=""
                  color="primary"
                  :content="client.contactPCCrise && client.contactPCCrise.length || '0'"
                  inline
                ></v-badge>
                <v-btn icon @click="addContact('contactPCCrise')">
                  <v-icon title="Ajouter un contact" color="primary" >mdi-account-multiple-plus</v-icon>
                </v-btn>
                <v-btn v-if="client.contactPCCrise && client.contactPCCrise.length > 0" 
                  icon
                  @click="toggleContacts('contactPCCrise')"
                  >
                  <v-icon>{{ contactPCCrise ? 'mdi-chevron-up': 'mdi-chevron-down' }}</v-icon>
                </v-btn>
              </v-toolbar>
            </div>
            <div class="contact-container" v-if="contactPCCrise">
              <contact-field
                v-for="(contact, index) in client.contactPCCrise"
                :key="index"
                :contact="contact"
                :title="`${contact.firstname + ' ' + contact.lastname + ' '}Contact  ${index + 1}`"
                @delete-contact="deleteContact('contactPCCrise', index)"
              ></contact-field>
            </div>

            <!-- Contact comptabilité -->
            <div class="d-flex align-center ">
                <v-toolbar class="elevation-0">
                  <h3>Contact comptabilité</h3>
                  <v-badge
                    class=""
                    color="primary"
                    :content="client.contactComptabilite && client.contactComptabilite.length || '0'"
                    inline
                  ></v-badge>
                  <v-btn icon @click="addContact('contactComptabilite')">
                    <v-icon title="Ajouter un contact" color="primary" >mdi-account-multiple-plus</v-icon>
                  </v-btn>
                  <v-btn v-if="client.contactComptabilite && client.contactComptabilite.length > 0" icon @click="toggleContacts('contactComptabilite')">
                    <v-icon>{{ contactComptabilite ? 'mdi-chevron-up': 'mdi-chevron-down' }}</v-icon>
                  </v-btn>
                </v-toolbar>
            </div>
            <div class="contact-container" v-if="contactComptabilite">
              <contact-field
                v-for="(contact, index) in client.contactComptabilite"
                :key="index"
                :contact="contact"
                :title="`${contact.firstname + ' ' + contact.lastname + ' '}Contact  ${index + 1}`"
                @delete-contact="deleteContact('contactComptabilite', index)"
              ></contact-field>
            </div>
          </div>
          <v-divider vertical></v-divider>
        </vue-scroll>

        <!-- right form -->
        <div style="width: 50%;"
          class="d-flex flex-column align-stretch justify-space-between"
        >

          <!-- Informations contrat -->
          <div class="ml-3 mt-8 d-flex align-center">
            <h3>Informations contrat</h3>
            <!-- icon info -->
            <v-icon color="primary" class="ml-2">mdi-information</v-icon>
          </div>
            <v-divider></v-divider>
          <div class="d-flex flex-row align-start justify-space-between">
            <v-card-text style="width: 48%">
              <v-text-field
                label="TVA"
                v-model="client.informationContrat.tva"
              ></v-text-field>
              <v-text-field
                label="N° Contrat"
                v-model="client.informationContrat.contractNumber"
              ></v-text-field>
              <v-menu>
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="Date de résiliation"
                    :value="client.informationContrat.terminationDate | formatDate"
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  style="width: 100%"
                  v-model="client.informationContrat.terminationDate"
                  :locale="$i18n.locale"
                  color="primary"
                ></v-date-picker>
              </v-menu>
            </v-card-text>
            <v-card-text style="width: 48%">
              <v-text-field
                label="Adresse de facturation"
                v-model="client.informationContrat.billingAddress"
              ></v-text-field>
              <v-text-field
                label="SIRET"
                v-model="client.informationContrat.siret"
              ></v-text-field>
              <v-menu>
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="Date signature"
                    :value="client.informationContrat.signatureDate | formatDate"
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  style="width: 100%"
                  v-model="client.informationContrat.signatureDate"
                  :locale="$i18n.locale"
                  color="primary"
                ></v-date-picker>
              </v-menu>
            </v-card-text>
          </div>
          <!-- Checkbox section for available services -->
          <p class="ml-3">Sélectionner les services</p>
          <v-expansion-panels style="width: 50%">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span>Services disponibles</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-checkbox
                  v-for="service in availableServices"
                  :key="service"
                  :label="service"
                  class="ml-3"
                  color="primary"
                  hide-details
                  :value="service"
                  :class="{
                    'selected-service': client.informationContrat.offeredServices.includes(
                      service
                    ),
                  }"
                  :checked="
                    client.informationContrat.offeredServices.includes(service)
                  "
                  v-model="client.informationContrat.offeredServices"
                ></v-checkbox>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue-darken-1" variant="text" @click="closeModal">
          Annuler
        </v-btn>
        <v-btn color="warning" variant="text" @click="saveAndCloseModal" :disabled="isSubmitDisabled">
          éditer
        </v-btn>
      </v-card-actions>
    </v-card>
</template>
<script>
import CodeSnippet from '@/Components/Commons/UiElements/CodeSnippet.vue';
import ClientService from '@/Services/SupportVision/ClientService';
import ContactField from './contactField';
import { mapMutations, mapState } from 'vuex';
import moment from 'moment';

export default {
  name: 'formClientUpdate',
  components: {
    CodeSnippet,
    ContactField,
  },
  data: () => ({
    contactCommercial: false,
    contactProjet: false,
    contactPCCrise: false,
    contactComptabilite: false,
    dialogUpdate: false,
    client: {
      _id: '',
      name: '',
      commercialName: '',
      contactCommercial: [],
      contactProjet: [],
      contactPCCrise: [],
      contactComptabilite: [],
      contract: {
        number: '',
        terminationDate: '',
      },
      informationContrat: {
        contractNumber: '',
        tva: '',
        siret: '',
        billingAddress: '',
        offeredServices: [],
        signatureDate: '',
        terminationDate: '',
      },
      selectedServices: [],
    },
    availableServices: ['planetOnlineFull', 'trasheures', 'planeteBooking'],
  }),
  filters: {
    formatDate(date) {
      if (!date) return '';
      return moment(date).format('DD/MM/YYYY');
    },
  },
  computed: {
    ...mapState(['selectedClientId']),
    filteredAvailableServices() {
      // Filtre les services disponibles pour afficher uniquement ceux non sélectionnés
      return this.availableServices.filter(
        (service) =>
          !this.client.informationContrat.offeredServices.includes(service)
      );
    },
    isSubmitDisabled() {
      return (
        !this.client.name
      );
    },
  },
  watch: {
    selectedClientId() {
      this.loadClientData(this.selectedClientId);
    },
  },
  methods: {
    // récupérer les données du client
    async loadClientData(clientId) {
      this.client = await ClientService.getClientById(clientId);

      this.client.selectedServices = this.client.informationContrat.offeredServices.slice();
      this.client.informationContrat.signatureDate =
        this.client.informationContrat.signatureDate != null
          ? this.client.informationContrat.signatureDate.split('T')[0]
          : this.client.informationContrat.signatureDate;
      this.client.informationContrat.terminationDate =
        this.client.informationContrat.terminationDate != null
          ? this.client.informationContrat.terminationDate.split('T')[0]
          : this.client.informationContrat.terminationDate;

      this.client.selectedServices = this.client.informationContrat.offeredServices.slice();
    },
    closeModal() {
      this.dialogUpdate = false;
      this.$emit('close-modal');
    },
    resetForm() {
      this.client = {
        _id: '',
        name: '',
        commercialName: '',
        contactCommercial: [],
        contactProjet: [],
        contactPCCrise: [],
        contactComptabilite: [],
        contract: {
          number: '',
          terminationDate: '',
        },
        informationContrat: {
          contractNumber: '',
          offeredServices: [],
          signatureDate: '',
          terminationDate: '',
        },
        selectedServices: [],
      };
    },
    addContact(contactType) {
      const contacts = this.client[contactType];
      if (!contacts) {
        return;
      }
      if (contactType === 'contactComptabilite') {
        contacts.push({
          lastname: '',
          firstname: '',
          email: '',
          phone: '',
          billingAddress: '',
          siret: '',
          tva: '',
        });
        return;
      }
      contacts.push({
        lastname: '',
        firstname: '',
        email: '',
        phone: ''
      });
    },
    deleteContact(contactType, index) {
      this.client[contactType].splice(index, 1);
      this[contactType] = !this[contactType];
    },
    toggleContacts(contactType) { 
      this[contactType] = !this[contactType];
    },
    async saveAndCloseModal() {
      // envoyer l id et le client
      const res = await ClientService.updateClient(this.selectedClientId, this.client);
      if (res && res.message == 'Client déjà existant') {
        this.$nError('Client déjà existant');
      } else {
        this.$nSuccess('Client modifié avec succès');
        this.closeModal();
      }
    },
  },
  async mounted() {
    this.loadClientData(this.selectedClientId);
  },
};
</script>

<style scoped>
.selected-service {
  font-weight: bold;
}
.contact-container {
  max-height: 30vh;  
  overflow-y: auto;  
  min-height: 30vh;
  /* afficher la barre de defilement */
  scrollbar-width: thin;
  scrollbar-color: #c5c5c5 #f5f5f5;
  height: 100%;
}
</style>
