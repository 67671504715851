<template>
  <v-container fluid>
    <v-toolbar rounded>
      <v-toolbar-title class="text-h5" elevation="20">Clients</v-toolbar-title>
      <!-- icon info avec le nombre de client -->
      <v-badge
        class="ml-4"
        color="primary"
        :content="clientCount"
        overlap
      ></v-badge>
      <v-spacer></v-spacer>
      <v-btn @click="openCreateModal" color="primary" small>Créer</v-btn>
    </v-toolbar>

    <v-text-field 
      class="mt-4"
      v-model="search"
      append-icon="mdi-magnify" 
      label="Search" 
      single-line
      clearable
    ></v-text-field> 
    
    <v-data-table
      :headers="headers"
      :items="clientsList"
      :items-per-page="10"
      :search="search"
      class="elevation-1"
      :loading="loader"
      :footer-props="{
        'items-per-page-text': $t('doubleAuth.rows-per-page'),
        'items-per-page-all-text': $t('doubleAuth.all'),
      }"
    >
     <template v-slot:item="{ item }">
        <tr style="height: 5rem;">
          <td>
            <span style="font-size: 1rem;">{{ formatClientName(item.name) }}</span>  <br>
            <span style="font-size: 0.8rem;">Nombre de bases: {{ groupByClient[item.name] || 0 }}</span> 
          </td>
          <!-- <td>{{ groupByClient[item.name] || 0 }}</td> -->
          <td  style="height: 5rem;" class="d-flex align-center">
            <!-- switch -->
            <v-switch
              dense
              v-model="item.isActive"
              @change="onSwitchChange(item._id)"
            ></v-switch>
            <v-btn icon @click="openUpdateModal(item._id)">
              <v-icon>mdi-square-edit-outline</v-icon>
            </v-btn>
            <v-btn color="error" icon @click="openDeleteModal(item._id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <!-- dialog formClientCreate -->
    <v-dialog v-model="dialogCreate" max-width="90%">
      <formClientCreate @close-modal="closeCreateModal" @client-created="refreshClientsList" />
    </v-dialog>
    <!-- dialog formClientUpdate -->
    <v-dialog v-model="dialogUpdate" max-width="90%">
      <formClientUpdate @close-modal="closeUpdateModal" />
    </v-dialog>
    <!-- dialog alertDelete -->
    <v-dialog v-model="alertDelete" max-width="25%">
      <alertDeleteClient @close-modal="closeAlertDeleteModal" />
    </v-dialog>
  </v-container>
</template>

<script>
import ClientService from '@/Services/SupportVision/ClientService';
import { mapMutations, mapState } from 'vuex';
import alertDeleteClient from '../Clients/alertDeleteClient.vue';
import formClientCreate from '../Clients/formClientCreate.vue';
import PlaneteOnlineService from "@/Services/PlaneteOnlineService";
import formClientUpdate from '../Clients/formClientUpdate.vue';
import BasesService from '@/Services/SupportVision/BasesService';

export default {
  name: 'Clients',
  data() {
    return {
      dialogCreate: false,
      dialogUpdate: false,
      alertDelete: false,
      hotlineAccessList: [],
      clientsList: [],
      isActive: false,
      resultCount: '',
      loader: false,
      search: '',
      headers: [
        { text: 'Nom', value: 'name', width : '95%' },
        // { text: 'Nombre de bases', value: 'baseCount', width : '80%'},
        { text: 'Actions', value: 'actions', sortable: false, align: 'center' },
      ],
    };
  },
  components: {
    formClientCreate,
    formClientUpdate,
    alertDeleteClient,
  },
  watch: {
    isActive() {
      this.refreshClientsList();
    },
  },
  computed: {
    ...mapMutations(['setSelectedClientId, setBasesByClientCount', 'setBaseList']),
    ...mapState(['basesByClientCount', 'baseList']),
    groupByClient() {
      // recuperer le nom du client et le nombre de base par client
      const basesByClientCount = {};

      this.baseList.data.forEach((base) => {
        if (!basesByClientCount[base.client]) {
          basesByClientCount[base.client] = 0;
        }
        basesByClientCount[base.client] += 1;
      });
      return basesByClientCount;
    },
    // retourne le nombre de client total
    clientCount() {
      return this.clientsList.length === 0 ? '0' : this.clientsList.length;
    },
  },
  methods: {
    openCreateModal() {
      this.dialogCreate = true;
    },
    closeCreateModal() {
      this.dialogCreate = false;
      this.refreshClientsList();
    },
    openUpdateModal(clientId) {
      this.dialogUpdate = true;
      this.$store.commit('setSelectedClientId', clientId);
    },
    closeUpdateModal() {
      this.dialogUpdate = false;
      this.refreshClientsList();
    },
    openDeleteModal(clientId) {
      this.$store.commit('setSelectedClientId', clientId);
      this.alertDelete = true;
    },
    closeAlertDeleteModal() {
      this.alertDelete = false;
      this.refreshClientsList();
    },
    // formater le nom du client avec la premiere lettre en majuscule
    formatClientName(text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
    async refreshClientsList() {
      this.loader = true;
      this.clientsList = await ClientService.getClientList();
      this.loader = false;
    },
    async onSwitchChange(clientId) {
      // change la valeur de isActive selon le bon client
      const client = this.clientsList.find((client) => client._id === clientId);
      await ClientService.updateClient(clientId, client);
    },
    async alimentClientList() {
      let clientExist = await PlaneteOnlineService.getBases();
      // console.log("clientExist", clientExist);
      // alimente la liste des clients avec les clients de planete online qui a la clé baseName et le reste des informations met à null
      clientExist.forEach((client) => {
        let clientOjb = {
          name: client.name,
          commercialName: null,
          informationContrat: {
            contractNumber: "",
            signatureDate: null,
            terminationDate: null,
          },
          offeredServices: null,
          contactsCommercial: [],  
          contactsProjet: [],  
          contactsPCCrise: [],  
          contactComptabilite: [],
        };
        // console.log(clientOjb);
        ClientService.createClient(clientOjb);
      });

    },
  },
  async mounted() {
    this.clientsList = await ClientService.getClientList();
    const bases = await BasesService.getBasesList();
    this.$store.commit('setBaseList', bases);

    // this.alimentClientList();
  },
};
</script>
